<template>
  <div
    class="fullscreen bg-blue text-white text-center q-pa-md flex flex-center"
  >
    <div>
      <div style="font-size: 20vh">404</div>
      <div class="text-h2" style="opacity: 0.4">Página no encontrada</div>
      <v-btn class="mt-5" color="teal" dark to="/"> Inicio </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "Error404",
};
</script>
